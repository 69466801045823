import CMSLayout from '@/layout/CMSLayout.vue';
import HomeLayout from '@/layout/HomeLayout.vue';

export const SsoConnectRouter = {
    path: '/',
    component: HomeLayout,
    children: [
        {
            path: '/sso/mirakl',
            name: 'SSOMirakl',
            // lazy load
            component: () => import('../page/SsoPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Mirakl SSO'
            },
        },
        {
            path: '/connect/mirakl-cb',
            name: 'MiraklCallback',
            // lazy load
            component: () => import('../page/SsoPage.vue'),
            meta: {
                onlyWhenLoggedOut: true,
                public: true,
                title: 'Mirakl Connect'
            },
        }
    ],
}