
import { Vue, Options } from 'vue-class-component'
import Sidebars from './sidebar'
import { useRoute } from 'vue-router'

import { UserType } from '@/constants/UserType'

@Options({
  //   props: {
  //     isCollapse: "",
  //   },
})
export default class AppSidebar extends Vue {
  sidebars: any = []
  listPlatformExist: any = []
  route = useRoute()
  currentRoter = ''
  isCollapse = false
  icon = 'el-icon-caret-left'
  existShopifyConnect = false
  accountSelected: any
  accountSelectedId = ''
  accounts: any = []
  accountNotBanned: any = []
  isShowUserSidebar = false

  async created() {
    this.menuSidebar()
    this.currentRoter = this.route.path.replace('/', '')
    const authenticResponse = await this.$store.state.auth.dataLogin
    this.accountSelected = await this.$store.state.auth.accountSelected
    this.accountSelectedId = this.accountSelected._id
    this.accounts = authenticResponse.account
    if (this.accounts.length > 0) {
      this.accounts.forEach((acc: any, i: any) => {
        // if (acc.owner == authenticResponse.id) {
        //   if (acc._id == this.accountSelectedId) {
        //     this.isShowUserSidebar = true;
        //     this.sidebars.push({
        //       name: "Users",
        //       url: "users",
        //       icon: "circle-user",
        //       isSuper: true,
        //       isClient: true,
        //     });
        //   }
        // }
        if (acc.banned.length > 0) {
          if (!acc.banned.includes(authenticResponse.id)) {
            this.accountNotBanned.push(acc)
          }
        } else this.accountNotBanned.push(acc)
      })
      localStorage.setItem(
        'isShowUserSidebar',
        this.isShowUserSidebar.toString()
      )
    }
    this.accounts = this.accountNotBanned.map((acc: any) => {
      return {
        name: acc.name,
        id: acc._id
      }
    })

    if (
      this.accountSelected.connectionData[0]?.connections[0]?.type == 'shopify' &&
      [UserType.Normal, UserType.Partner].includes(authenticResponse.type)
    ) {
      this.sidebars.push(
        {
          name: 'My Subscriptions',
          url: 'subscriptions',
          icon: 'wallet',
          isSuper: false,
          isClient: true,
          isPartner: true
        },
        {
          name: 'My Profile',
          icon: 'address-card',
          isSuper: false,
          isClient: true,
          isPartner: true,
          children: {
            subItem: [{ name: 'My Details', url: 'account-detail' }]
          }
        }
      )
    } else {
      this.accountSelected.connectionData.forEach((goi: any) => {
        goi.connections.forEach((connection: any) => {
          if (
            !this.listPlatformExist.includes(connection.type) &&
            !connection.deletedDate
          )
            this.listPlatformExist.push(connection.type)
          if (connection.type == 'shopify' && !connection.deletedDate) {
            this.existShopifyConnect = true
          }
        })
      })

      if (this.existShopifyConnect) {
        if (this.listPlatformExist.length >= 3 && [UserType.Normal, UserType.Partner].includes(authenticResponse.type)) {
          this.sidebars.push({
            name: 'My Profile',
            icon: 'address-card',
            isSuper: false,
            isClient: true,
            isPartner: true,
            children: {
              subItem: [
                { name: 'My Details', url: 'account-detail' },
                { name: 'My Payments', url: 'account-payment' }
              ]
            }
          })
        }

        if (this.listPlatformExist.length == 2 && [UserType.Normal, UserType.Partner].includes(authenticResponse.type)) {
          this.sidebars.push(
            {
              name: 'My Subscriptions',
              url: 'subscriptions',
              icon: 'wallet',
              isSuper: false,
              isClient: true,
              isPartner: true,
            },
            {
              name: 'My Profile',
              icon: 'address-card',
              isSuper: false,
              isClient: true,
              isPartner: true,
              children: {
                subItem: [{ name: 'My Details', url: 'account-detail' }]
              }
            }
          )
        }
      } else {
        if ([UserType.Normal, UserType.Partner].includes(authenticResponse.type)) {
          this.sidebars.push({
            name: 'My Profile',
            icon: 'address-card',
            isSuper: false,
            isClient: true,
            isPartner: true,
            children: {
              subItem: [
                { name: 'My Details', url: 'account-detail' },
                { name: 'My Payments', url: 'account-payment' }
              ]
            }
          })
        }
      }
    }
    if (this.accountSelected.isFreeAccount) {
      this.sidebars = this.sidebars.filter((item: any) => {
        return item.name != 'My Subscriptions'
      })
    }
  }

  menuSidebar() {
    const isOwner = this.$store.state.auth?.accountSelected?.owner === this.$store.state.auth.dataLogin.id
    Sidebars[0].forEach((menu) => {
      if (
        this.$store.state.auth.dataLogin.type === UserType.SuperAdmin ||
        this.$store.state.auth.dataLogin === 1
      ) {
        if (menu.isSuper) {
          this.sidebars.push(menu)
        }
      } else if (this.$store.state.auth.dataLogin.type === UserType.Partner && isOwner) {
        if (menu.isPartner) {
          this.sidebars.push(menu)
        }
      } else {
        if (menu.isClient) {
          this.sidebars.push(menu)
        }
      }
    })
  }
  showSidebar() {
    if (this.isCollapse) {
      this.isCollapse = false
      this.icon = 'el-icon-caret-left'
    } else {
      this.isCollapse = true
      this.icon = 'el-icon-caret-right'
    }
    this.$emit('finishHandle')
  }
  handleChangeAccount() {
    console.log(this.accountSelectedId)
    localStorage.setItem('account', this.accountSelectedId)
    window.location.reload()
  }
}
